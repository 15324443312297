import { createStore } from "vuex";
import { config } from "vuex-module-decorators";

import VuexPersistence from "vuex-persist";

import AuthModule from "@/store/modules/AuthModule";
import BodyModule from "@/store/modules/BodyModule";
import BreadcrumbsModule from "@/store/modules/BreadcrumbsModule";
import ConfigModule from "@/store/modules/ConfigModule";

config.rawError = true;

// eslint-disable-next-line
const vuexLocal = new VuexPersistence<any>({
  //strictMode: true,
  storage: window.localStorage,
});

const store = createStore({
  modules: {
    AuthModule,
    BodyModule,
    BreadcrumbsModule,
    ConfigModule,
  },
  plugins: [vuexLocal.plugin],
});

export default store;
