import { createI18n } from "vue-i18n";

const messages = {
  en: {
    notification: {
      notifications: "Notifications",
      newNotification: "New notification",
      viewAll: "View all",
    },
    comment: {
      confirmDelete: "Are you sure you want to delete this comment?",
      deleted: "You comment has been deleted successfully",
      comments: "Comments",
      reply: "Reply",
      acceptDelete: "Yes, delete it!",
      delete: "Delete",
      okay: "OK!",
      errorText: "Something is wrong",
    },
    timetable: {
      monday: "Monday",
      tuesday: "Tuesday",
      wednesday: "Wednesday",
      thursday: "Thursday",
      friday: "Friday",
      saturday: "Saturday",
      sunday: "Sunday",
      lesson: "lesson",
      skip: "skip",
      cabinet: "Room",
      court: "court",
      break: "Break",
      sickcall: "Sick call for today",
      print: "Print",
    },
    psychologist: {
      title: {
        question: "How are you feeling today?",
        answer: "Here we are to Help",
      },
      nurse: "Nurse",
      psychologue: "Psychologue",
    },
    attendance: {
      attendance: "Attendance",
      performance: "Performance",
      presenceTitle: "Session Attendance \nFor the current academic year",
      performanceTitle: "Session Performance \nFor the current academic year",
      goodAttendance: "Great, you always attending class. keep it up.",
      homeworks: "Homeworks",
      forgetHomeworks: "Don't forget to turn in your task",
      tests: "Tests",
      testStatue: "You take {nb} subjects at this semester",
      yourAbsencesThisYear: "Number of your absences in this year",
      yourDelaysThisYear: "Number of your dealys in this year",
      yourAttendanceThisMonth: "Your Attendance in this month",
    },
    performance: {
      title: "Academic Performance",
      avg: "Avg. {avg} completed lessons",
      math: "Mathematics",
      chemistry: "Chemistry",
      lessons: "Lessons",
      completedHomeworks: "Completed homeworks",
      completedHomeworksThisYear: "Number of completed homeworks this year",
      markedHomeworks: "Marked homeworks",
      yourMarkedHomeworksThisYear: "Number of marked homeworks this year",
      specificHomeworks: "Special homeworks",
      yourSpecificHomeworksThisYear:
        "Number of special homeworks you got this year",
    },
    news: {
      news: "News",
      reaction: "Your Reactions",
      totalPosts: "Number of Posts",
      newPosts: "New Posts",
      seeAll: "See all",
      noNews: "The administration has not published any news yet",
    },
    accueilHomeworks: {
      today: "Today Homeworks",
      recent: "Recent",
      future: "Future",
      week: "Homeworks of this week",
      weekDeadline: "This week submissions",
      done: "Done",
      inProcess: "In Progress",
    },
    accueilRecommended: {
      title: "Recommended for you",
      nbrCourses: "{nb} Courses",
      all: "All courses",
    },
    login: {
      title: "Log in",
      forgetAuth:
        "If you have forgotten your information, please contact the administration",
      emailAndUsername: "Email/Username",
      requiredEmailAndUsername: "Username or email is a required field",
      password: "Password",
      requiredPassword: "Password is a required field",
      passwordCondition: "Password must be at least 4 characters",

      yourSchool: "Your school",
      chooseSchool: "Please choose your school",
      submit: "Log in",
      success: "You have successfully logged in!",
      getIt: "Ok, got it!",
      incorrectInfo: "The username or password is incorrect",
      tryAgain: "Try again!",
      loginForgotPassword: "Forgot your password?",

      linkExpired: "The link you followed has expired",
      linkExpiredDesc: "The link you followed has expired. please try again.",
      passwordChanged: "Your password has been changed successfully",
      close: "Close",
      forgotPassword: "Forgot Password",
      forgotPasswordText:
        "Enter your email to reset your password. If you do not have an email, please contact the administrator",
      resetPasswordReqested: "Password reset requested",
      resetPasswordReqestedDesc:
        "We have sent you an email, please check it to reset your password!",
      cancel: "Cancel",
      resetSubmit: "Submit",
      emailNotExist: "This email does not exist",

      requiredEmail: "The email is a required field",
      invalidEmail: "The email must be a valid",

      parentEmail: "Your parent's email",
      yourUsername: "Your username",
      requiredUsername: "Username is a required field",
    },
    phoneNumber: "Phone number",
    headMaster: "Headmaster",
    subjects: "Subjects",
    subject: "Subject",
    courses: "Courses",
    course: "Course",
    exercises: "Exercises",
    exercise: "Exercise",
    nbrCourses: "There are {nb}  courses",
    nbrExercises: "There are {nb} exercises",
    title: "Title",
    lastComment: "Last comment by",
    teacher: "Teacher",
    publicationDate: "Publication date",
    search: "Search",
    deadline: "Deadline",
    module: "Module",
    description: "Description",
    privateMessage: "Message privé",
    resources: "Resources",
    homework: "Homework",
    marked: "Marked",
    notMarked: "Not marked",
    yes: "Yes",
    no: "No",
    drobFiles: "Drop files here",
    fileSize: "pdf/doc files of size less than 5 MB",
    reply: "Reply",
    mark: "Grade",
    notYetMarked: "Not noted yet",

    presence: "Presence",
    illnesses: "Illnesses",
    delays: "Delays",
    absences: "Absences",
    general: "General",
    settings: "Settings",
    fullname: "Full Name",
    firstname: "First name",
    lastname: "Last name",
    birthday: "Birthday",
    telephoneNumber: "Phone number",
    gender: "Gender",
    address: "Address",
    editProfile: "Edit profile",
    profileDetails: "Profile details",
    profileDetailsUpdated:
      "Your Profile details has been updated successfully!",
    boy: "Boy",
    girl: "Girl",
    virifed: "Verified",
    allowedAvatarFiles: "Allowed file types: png, jpg, jpeg.",
    cancel: "Cancel",
    saveChanges: "Save changes",
    pleaseWait: "Please wait...",
    securitySettings: "Security Settings",
    currentPasswordIncorrect:
      "The current password is incorrect, please try again",
    emailAddress: "Email address",
    changeEmail: "Change email",
    updateEmail: "Update email",
    enterNewEmail: "Enter a new email address",
    confirmPassword: "Confirm the password",
    password: "Password",
    resetPassword: "Reset password",
    passwordRule:
      "Password must be at least 8 characters long and contain symbols",
    currentPassword: "Current Password",
    newPassword: "New Password",
    confirmNewPassword: "Confirm the new password",
    updatePassword: "Update password",
    profileCompletion: "Profile Completion",

    assign: "Assign",
    completed: "Completed",
    missing: "Missing",

    arabic: "Arabic",
    english: "English",
    french: "French",

    signOut: "Sign Out",
    language: "Language",
    accountSettings: "Account Settings",
    myProfile: "My Profile",
    student: "Student",
    updatedPhoto: "Photo updated!",
    updatePhotoError: "Something wrong",

    requiredFirstname: "First name is a required field",
    requiredLastname: "Last name is a required field",
    requiredPhone: "Phone number is a required field",
    invalidPhone: "The phone number must contain 8 digits",
    requiredAddress: "Address is a required field",
    requiredCurrentPassword: "Current password is a required field",
    requiredNewPassword: "New Password is a required field",
    requiredConfirmPassword: "Password Confirmation is a required field",
    passwordConfirmationCondition:
      "Password Confirmation must be at least 4 characters",
    passwordNewCondition: "New Password must be at least 4 characters",
    passwordMustMatch: "Password must match",
    passwordUpdated: "Your password has been updated successfully",

    details: "Details",
    schoolarYear: "Schoolar Year",
    uploadFiles: "Upload files",
    selectSubmissionMethod: "Select submission method",
    writeAnswer: "Write a answer",
    homeworkSubmitted: "Your homework has been submitted",
    answer: "Answer",
    submit: "Submit",
    allTrimesters: "All trimesters",
    trimester: "Trimester",
    invalidSubmission: "Something went wrong",
    invalidSubmissionText: "You must submit files or write the answer",

    dashboard: "Dashboard",
    layoutBuilder: "Layout builder",
    craft: "Crafted",
    pages: "Pages",
    profile: "Profile",
    profileOverview: "Overview",
    projects: "Projects",
    campaigns: "Campaigns",
    documents: "Documents",
    connections: "Connections",
    wizards: "Wizards",
    horizontal: "Horizontal",
    vertical: "Vertical",
    account: "Account",
    accountOverview: "Overview",
    authentication: "Authentication",
    basicFlow: "Basic Flow",
    signIn: "Sign-in",
    signUp: "Sign-up",
    passwordReset: "Password Reset",
    multiStepSignUp: "Multi-steps Sign up",
    error404: "Error 404",
    error500: "Error 500",
    apps: "Apps",
    chat: "Chat",
    privateChat: "Private Chat",
    groupChat: "Group Chat",
    drawerChat: "Drawer Chat",
    widgets: "Widgets",
    widgetsLists: "Lists",
    widgetsStatistics: "Statistics",
    widgetsCharts: "Charts",
    widgetsMixed: "Mixed",
    widgetsTables: "Tables",
    widgetsFeeds: "Feeds",
    changelog: "Changelog",
    docsAndComponents: "Docs & Components",
    megaMenu: "Mega Menu",
    exampleLink: "Example link",
    modals: "Modals",
    inviteFriends: "Invite Friends",
    viewUsers: "View Users",
    upgradePlan: "Upgrade Plan",
    shareAndEarn: "Share & Earn",
    forms: "Forms",
    newTarget: "New Target",
    newCard: "New Card",
    newAddress: "New Address",
    createAPIKey: "Create API Key",
    twoFactorAuth: "Two Factor Auth",
    createApp: "Create App",
    createAccount: "Create Account",
    documentation: "Documentation",
    components: "Components",
    activity: "Activity",
    customers: "Customers",
    gettingStarted: "Getting Started",
    customersListing: "Customers Listing",
    customerDetails: "Customers Details",
    calendarApp: "Calendar",
    subscriptions: "Subscriptions",
    getStarted: "Getting Started",
    subscriptionList: "Subscription List",
    addSubscription: "Add Subscription",
    viewSubscription: "View Subscription",
    builder: "Builder",
    home: "Home",
    todo: "Todo",
    quiz: "Quiz",
    submittedQuiz: "Your quiz has been submitted successfully",
    quizSubmissionError: "You must select at least one answer",
  },

  fr: {
    notification: {
      notifications: "Notifications",
      newNotification: "Nouvelle notification",
      viewAll: "Voir tout",
    },
    comment: {
      confirmDelete: "Êtes-vous sûr de vouloir supprimer ce commentaire?",
      deleted: "Votre commentaire a été supprimé avec succès",
      comments: "Commentaires",
      reply: "Réponse",
      acceptDelete: "Oui, supprimez-le !",
      okay: "D'accord!",
      errorText: "Something is wrong",
      tryAgain: "Veuillez réessayer",
      delete: "Supprimer",
    },
    timetable: {
      monday: "Lundi",
      tuesday: "Mardi",
      wednesday: "Mercredi",
      thursday: "Jeudi",
      friday: "Vendredi",
      saturday: "Samedi",
      sunday: "Dimanche",
      lesson: "leçon",
      skip: "Passer",
      cabinet: "Salle",
      court: "cour",
      break: "Pause",
      sickcall: "Appel malade pour aujourd'hui",
      print: "Imprimer",
    },
    psychologist: {
      title: {
        question: "Comment vous sentez-vous aujourd'hui?",
        answer: "Nous sommes là pour vous aider",
      },
      nurse: "Infirmière",
      psychologue: "Psychologue",
    },
    attendance: {
      attendance: "Présence",
      performance: "Performance",
      presenceTitle: "Présence aux sessions \nPour l'année académique en cours",
      performanceTitle:
        "Performance aux sessions \nPour l'année académique en cours",

      goodAttendance:
        "Bien, vous assistez toujours à la classe. continuez comme ça",
      homeworks: "Exercices",
      forgetHomeworks: "N'oubliez pas de rendre votre tâche",
      tests: "Examens",
      testStatue: "Vous prenez {nb} matières à ce semestre",
      yourAbsencesThisYear: "Nombre de vos absences cette année",
      yourDelaysThisYear: "Nombre de vos transactions cette année",
      yourAttendanceThisMonth: "Votre assiduité ce mois-ci",
    },
    performance: {
      title: "Performance académique",
      avg: "Moy. {avg} des cours terminés",
      math: "Mathématiques",
      chemistry: "Chimie",
      lesons: "Cours",
      completedHomeworks: "Exercices terminés",
      completedHomeworksThisYear: "Nombre de exercises complétés cette année",
      markedHomeworks: "Exercices notés",
      yourMarkedHomeworksThisYear: "Nombre de exercises notés cette année",
      specificHomeworks: "Exercices spéciaux",
      yourSpecificHomeworksThisYear:
        "Nombre de exercises spéciaux que vous avez eu cette année",
    },
    news: {
      news: "Actualités",
      reaction: "Vos réactions",
      totalPosts: "Total de publications",
      newPosts: "Nouvelles Publications",
      seeAll: "Voir tout",
      noNews: "l'administration n'a publié aucune actualité",
    },
    accueilHomeworks: {
      today: "Les exercices d'aujourd'hui",
      recent: "Récent",
      future: "Avenir",
      week: "Les exercices de cette semaine",
      weekDeadline: "Soumissions de cette semaine",
      done: "Terminé",
      inProcess: "En cours",
    },
    accueilRecommended: {
      title: "Recommandé pour vous",
      nbrCourses: "{nb} Cours",
      all: "Tous les cours",
    },
    login: {
      title: "Se connecter",
      forgetAuth:
        "Si vous avez oublié vos données, merci de contacter l'administration",

      emailAndUsername: "Email/Nom d'utilisateur",
      requiredEmailAndUsername:
        "Le nom d'utilisateur ou l'email est un champ obligatoire",
      password: "Mot de Passe",
      requiredPassword: "L'mot de passe est un champ obligatoire",
      passwordCondition: "Le mot de passe doit comporter au moins 4 caractères",

      yourSchool: "Votre école",
      chooseSchool: "Veuillez choisir votre école",
      submit: "Continue",
      success: "Vous vous êtes connecté avec succès!",
      getIt: "D'accord",
      incorrectInfo: "Le nom d'utilisateur ou mot de passe est incorrect",
      tryAgain: "Réessayer!",
      loginForgotPassword: "Mot de passe oublié?",

      linkExpired: "Le lien que vous avez suivi a expiré",
      linkExpiredDesc:
        "Le lien que vous avez suivi a expiré. Veuillez réessayer.",
      passwordChanged: "Votre mot de passe a été changé avec succès",
      close: "Fermer",
      forgotPassword: "Mot de passe oublié",
      forgotPasswordText:
        "Entrez votre email pour réinitialiser votre mot de passe. Si vous n'avez pas d'email, veuillez contacter l'administrateur",
      resetPasswordReqested: "Réinitialisation du mot de passe demandée",
      resetPasswordReqestedDesc:
        "Nous vous avons envoyé un email, veuillez le vérifier pour réinitialiser votre mot de passe !",
      cancel: "Annuler",
      resetSubmit: "Submit",
      emailNotExist: "Ce email n'existe pas",

      requiredEmail: "L'email est un champ obligatoire",
      invalidEmail: "L'email doit être valide",
      parentEmail: "L'email de tes parents",
      yourUsername: "Votre nom d'utilisateur",
      requiredUsername: "Le nom d'utilisateur est un champ obligatoire",
    },
    phoneNumber: "Numéro de téléphone",
    headMaster: "Directeur",
    subjects: "Matières",
    subject: "Matière",
    courses: "Cours",
    course: "Cour",
    exercises: "Exercices",
    exercise: "Exercice",
    nbrCourses: "Il y a {nb} cours",
    nbrExercises: "Il y a {nb} exercices",
    title: "Titre",
    lastComment: "Dernier commentaire par",
    teacher: "Enseignant",
    publicationDate: "Date de publication",
    search: "Recherche",
    deadline: "Date limite",
    module: "Module",
    description: "Description",
    privateMessage: "Message privé",
    resources: "Ressources",
    homework: "Devoir de la maison",
    marked: "Noté",
    notMarked: "Non noté",
    yes: "Oui",
    no: "Non",
    drobFiles: "Déposez les fichiers ici",
    fileSize: "fichiers pdf/doc d'une taille inférieure à 5 Mo",
    reply: "Réponse",
    mark: "Note",
    notYetMarked: "Pas encore evalué",
    presence: "Présence",
    illnesses: "Maladies",
    delays: "Retard",
    absences: "Absences",

    general: "Général",
    settings: "Paramètres",
    fullname: "Nom et prénom",
    firstname: "Prénom",
    lastname: "Nom",
    birthday: "Date de naissance",
    telephoneNumber: "Numéro de téléphone",
    gender: "Le sexe",
    address: "Adresse",
    editProfile: "Editer le profil",
    profileDetails: "Détails du profil",
    profileDetailsUpdated:
      "Les détails de votre profil ont été mis à jour avec succès !",
    boy: "Garçon",
    girl: "Fille",
    virifed: "Vérifié",
    allowedAvatarFiles: "Types de fichiers autorisés : png, jpg, jpeg.",
    cancel: "Annuler",
    saveChanges: "Sauvegarder les modifications",
    pleaseWait: "S'il vous plaît, attendez...",
    securitySettings: "Les paramètres de sécurité",
    currentPasswordIncorrect:
      "Le mot de passe actuel est incorrect, veuillez réessayer",
    emailAddress: "Adresse email",
    changeEmail: "Changer l'email",
    updateEmail: "Mettre à jour l'email",
    enterNewEmail: "Saisissez une nouvelle adresse email",
    confirmPassword: "Confirmez le mot de passe",
    password: "Mot de passe",
    resetPassword: "Réinitialiser le mot de passe",
    passwordRule:
      "Le mot de passe doit comporter au moins 8 caractères et contenir des symboles",
    currentPassword: "Mot de passe actuel",
    newPassword: "Nouveau mot de passe",
    confirmNewPassword: "Confirmez le nouveau mot de passe",
    updatePassword: "Mettre à jour le mot de passe",
    profileCompletion: "Achèvement du profil",
    assign: "Attribué",
    completed: "Terminé",
    missing: "Manquant",
    arabic: "Arabe",
    english: "Anglais",
    french: "Français",

    signOut: "Se déconnecter",
    language: "Langue",
    accountSettings: "Paramètres du compte",
    myProfile: "Mon profil",
    student: "Étudiant",
    updatedPhoto: "Photo mise à jour!",
    updatePhotoError: "Erreur interne",

    requiredFirstname: "Prénom est un champ obligatoire",
    requiredLastname: "Nom est un champ obligatoire",
    requiredPhone: "Télephone est un champ obligatoire",
    invalidPhone: "Le numéro de téléphone doit contenir 8 chiffres",

    requiredAddress: "Adresse est un champ obligatoire",

    requiredCurrentPassword: "Le mot de passe actuel est un champ obligatoire",
    requiredNewPassword: "Le nouveau mot de passe est un champ obligatoire",
    requiredConfirmPassword:
      "La confirmation du mot de passe est un champ obligatoire",
    passwordConfirmationCondition:
      "La confirmation du mot de passe doit contenir au moins 4 caractères",
    passwordNewCondition:
      "Le nouveau mot de passe doit comporter au moins 4 caractères",
    passwordMustMatch: "Le mot de passe doit correspondre",
    passwordUpdated: "Votre mot de passe a été mis à jour avec succès",

    details: "Détails",
    schoolarYear: "Année scolaire",
    uploadFiles: "Choisir des fichiers",
    selectSubmissionMethod: "Sélectionnez la méthode",
    writeAnswer: "Rédigez une réponse",
    homeworkSubmitted: "Vos devoirs ont été remis",
    answer: "Réponse",
    submit: "Submit",
    trimester: "Trimestre",
    allTrimesters: "Tous les trimestres",
    invalidSubmission: "Quelque chose s'est mal passé",
    invalidSubmissionText:
      "Vous devez soumettre des fichiers ou écrire la réponse",

    dashboard: "Générateur de mise",
    layoutBuilder: "En page",
    craft: "Fabriqué",
    pages: "Pages",
    profile: "Profil",
    profileOverview: "Aperçu",
    projects: "Projets",
    campaigns: "Campagnes",
    documents: "Documents",
    connections: "Connexions",
    wizards: "Sorciers",
    horizontal: "Horizontal",
    vertical: "Verticale",
    account: "Compte",
    accountOverview: "Aperçu",
    authentication: "Authentification",
    basicFlow: "Flux de base",
    signIn: "SS'identifier",
    signUp: "Inscrivez-vous",
    passwordReset: "Réinitialisation du mot de passe",
    multiStepSignUp: "S'Inscrire Multi-Étapes",
    error404: "Erreur 404",
    error500: "Erreur 500",
    apps: "Applications",
    chat: "Discuter",
    privateChat: "Discussion privée",
    groupChat: "Discussion de groupe",
    drawerChat: "Chat de tiroir",
    widgets: "Widgets",
    widgetsLists: "Listes",
    widgetsStatistics: "Statistiques",
    widgetsCharts: "Graphiques",
    widgetsMixed: "Mixte",
    widgetsTables: "Les tables",
    widgetsFeeds: "Flux",
    changelog: "Journal des modifications",
    docsAndComponents: "Documents & composants",
    megaMenu: "Méga Menu",
    exampleLink: "Exemple de lien",
    modals: "Modals",
    inviteFriends: "Inviter Des Amis",
    viewUsers: "Voir Les Utilisateurs",
    upgradePlan: "Plan De Mise À Niveau",
    shareAndEarn: "Partager Et Gagner",
    forms: "Formes",
    newTarget: "Nouvelle Cible",
    newCard: "Nouvelle Carte",
    newAddress: "Nouvelle Adresse",
    createAPIKey: "Créer Une Touche Api",
    twoFactorAuth: "Deux Facteurs D'Authentification",
    createApp: "Créer Une Application",
    createAccount: "Créer Un Compte",
    activity: "Activité",
    documentation: "Documentation",
    components: "Composants",
    customers: "Les Clients",
    gettingStarted: "Commencer",
    customersListing: "Liste Des Clients",
    customerDetails: "Détails Des Clients",
    calendarApp: "Calendrier",
    subscriptions: "Abonnements",
    getStarted: "Commencer",
    subscriptionList: "Liste D'Abonnement",
    addSubscription: "Ajouter Un Abonnement",
    viewSubscription: "Voir L'Abonnement",
    builder: "Constructeur",
    home: "Accueil",
    todo: "à faire",
    quiz: "Quiz",
    submittedQuiz: "Votre quiz a été soumis avec succès",
    quizSubmissionError: "Vous devez sélectionner au moins une réponse",
  },

  ar: {
    notification: {
      notifications: "الإشعارات",
      newNotification: "إشعار جديد",
      viewAll: "عرض الكل",
    },
    comment: {
      confirmDelete: "هل أنت متأكد من رغبتك في حذف هذا التعليق؟",
      deleted: "تم حذف التعليق بنجاح",
      comments: "التعليقات",
      reply: "تعليق",
      acceptDelete: "نعم، حذف",
      okay: "!حسنا",
      errorText: "شيئًا ما غير صحيح",
      tryAgain: "يرجى إعادة المحاولة مرة أخرى",
      delete: "حذف",
    },
    timetable: {
      monday: "الاثنين",
      tuesday: "الثلاثاء",
      wednesday: "الاربعاء",
      thursday: "الخميس",
      friday: "الجمعة",
      saturday: "السبت",
      sunday: "الأحد",
      lesson: "درس",
      skip: "تخطي",
      cabinet: "القاعة",
      court: "الفصل",
      break: "راحة",
      sickcall: "إتصال من أجل المرض اليوم",
      print: "طباعة",
    },
    psychologist: {
      title: {
        question: "كيف تشعر اليوم؟",
        answer: "نحن هنا لمساعدتك",
      },
      nurse: "تمريض",
      psychologue: "علم النفس",
    },
    attendance: {
      attendance: "الحضور",
      performance: "الأداء الدراسي",
      presenceTitle: "مستوى الحضور\nبالنسبة للعام الدراسي الحالي",
      performanceTitle: "مستوى الأداء\nبالنسبة للعام الدراسي الحالي",
      goodAttendance: "رائع ، أنت تحضر الفصل دائمًا. واصل.",
      homeworks: "الواجبات",
      forgetHomeworks: "لا تنسى تسليم مهمتك",
      tests: "الإختبارات",
      testStatue: "تدرس {nb} موضوعًا خلال هذا الفصل الدراسي",
      yourAbsencesThisYear: "عدد الغيابات في السنة الدراسية",
      yourDelaysThisYear: "عدد التأخيرات في السنة المدرسية",
      yourAttendanceThisMonth: "حضروك هذا الشهر",
    },
    performance: {
      title: "أداء أكاديمي",
      lessons: "الدروس",
      completedHomeworks: "التمارين المكتملة",
      completedHomeworksThisYear: "عدد التمارين المكتملة هذه السنة",
      markedHomeworks: "التمارين المنقطة",
      yourMarkedHomeworksThisYear: "عدد التمارين المنقطة هذه السنة",
      specificHomeworks: "التمارين المميزة",
      yourSpecificHomeworksThisYear:
        "عدد التمارين المميزة المتحصل عليها هذه السنة",
    },
    news: {
      news: "الأخبار",
      reaction: "تفاعلاتك",
      totalPosts: "عدد المنشورات",
      newPosts: "المنشورات الجديدة",
      seeAll: "الكل",
      noNews: "لم تنشر الإدارة أي أخبار بعد",
    },
    accueilHomeworks: {
      today: "واجبات اليوم",
      recent: "مؤخرًا",
      future: "القادمة",
      week: "واجبات هذا الأسبوع",
      weekDeadline: "تقديمات هذا الأسبوع",
      done: "مكتمل",
      inProcess: "قيد الإنجاز",
    },
    accueilRecommended: {
      title: "موصى به من أجلك",
      nbrCourses: "درس {nb}",
      all: "كل الدروس",
    },
    login: {
      title: "تسجيل دخول",
      forgetAuth:
        "في حالة نسيان معلومات تسجيل الدخول الخاصة بك، يرجى الإتصال بالإدارة",
      emailAndUsername: "إسم المستخدم أو البريد الإلكتروني",
      requiredEmailAndUsername: "إسم المستخدم أو البريد الإلكتروني مطلوب",
      password: "كلمة المرور",
      requiredPassword: "كلمة المرور مطلوبة",
      passwordCondition: "يجب أن تتكون كلمة المرور من 4 أحرف على الأقل",

      yourSchool: "مدرستك",
      chooseSchool: "إختر مدرستك",
      submit: "تسجيل الدخول",
      success: "!لقد تم تسجيل الدخول بنجاح",
      getIt: "!حسنا",
      incorrectInfo: "إسم المتخدم أو كلمة المرور غير صحيحة",
      tryAgain: "!أعد المحاولة",
      loginForgotPassword: "نسيت كلمة المرور؟",

      linkExpired: "لقد انتهت صلاحية هذا الرابط بالفعل",
      linkExpiredDesc: "لقد انتهت صلاحية هذا الرابط، يرجى إعادة المحاولة",
      passwordChanged: "تم تغيير كلمة المرور الخاصة بك بنجاح",
      close: "إغلاق",
      forgotPassword: "نسيت كلمة المرور",
      forgotPasswordText:
        "أدخل بريدك الإلكتروني لإعادة تعيين كلمة المرور الخاصة بك. إذا لم يكن لديك بريد إلكتروني ، يرجى الاتصال بالإدارة",
      resetPasswordReqested: "طلب إعادة تعيين كلمة المرور",
      resetPasswordReqestedDesc:
        "لقد أرسلنا لك بريدًا إلكترونيًا ، يرجى التحقق منه لإعادة تعيين كلمة المرور الخاصة بك!",
      cancel: "إلغاء",
      resetSubmit: "تفعيل",
      emailNotExist: "هذا البريد الإلكتروني غير موجود",

      requiredEmail: "البريد الالكتروني مطلوب",
      invalidEmail: "البريد الالكتروني غير صحيح",

      parentEmail: "البريد الإلكتروني الخاصة بالوالدين",
      yourUsername: "إسم المستخدم الخاص بك",
      requiredUsername: "إسم المستخدم مطلوب",
    },
    phoneNumber: "رقم الهاتف",
    headMaster: "المدير",
    subjects: "المواد",
    subject: "المادة",
    courses: "الدروس",
    course: "الدرس",
    exercises: "التمارين",
    exercise: "التمرين",
    nbrCourses: "درس {nb}  يوجد",
    nbrExercises: "تمرين {nb} يوجد",
    title: "العنوان",
    lastComment: "آخر تعليق",
    teacher: "الأستاذ",
    publicationDate: "تاريخ النشر",
    search: "بحث",
    deadline: "الموعد النهائي",
    module: "الوحدة",
    description: "الوصف",
    privateMessage: "رسالة خاصة",
    resources: "المصادر",
    homework: "الواجبات المنزلية",
    marked: "بالنقاط",
    notMarked: "بدون نقاط",
    yes: "نعم",
    no: "لا",
    drobFiles: "اسحب الملفات هنا",
    fileSize: "ملفات pdf / doc بحجم أقل من 5 ميجا بايت",
    reply: "رد",
    mark: "العدد",
    notYetMarked: "لم يقيم بعد",

    presence: "الحضور",
    illnesses: "المرض",
    delays: "التأخر",
    absences: "الغياب",
    general: "عام",
    settings: "الإعدادات",
    fullname: "الإسم الكامل",
    firstname: "الإسم",
    lastname: "اللقب",
    birthday: "تاريخ الولادة",
    telephoneNumber: "رقم الهاتف",
    gender: "الجنس",
    address: "العنوان",
    editProfile: "تعديل الملف الشخصي",
    profileDetails: "معلومات الملف الشخصي",
    profileDetailsUpdated: "!تم تحديث معلومات الملف الشخصي بنجاح",
    boy: "فتى",
    girl: "فتاة",
    virifed: "مفعل",
    allowedAvatarFiles: "أنواع الملفات المسموح بها هي : png، jpg، jpeg.",
    cancel: "إلغاء",
    saveChanges: "حفظ التغيرات",
    pleaseWait: "... يرجى الإنتظار قليلاً",
    securitySettings: "اعدادات الامان",
    currentPasswordIncorrect:
      "...كلمة المرور الحالية غير صحيحى. يرجى إعادة المحاولة",
    emailAddress: "البريد الإلكتروني",
    changeEmail: "تغيير البريد الإلكتروني",
    updateEmail: "تحديث البريد الإلكتروني",
    enterNewEmail: "البريد الإلكتروني الجديد",
    confirmPassword: "تأكيد كلمة المرور",
    password: "كلمة المرور",
    resetPassword: "إعادة تعيين كلمة المرور",
    passwordRule:
      "يجب أن تتكون كلمة المرور من 8 أحرف على الأقل وأن تحتوي على رموز",
    currentPassword: "كلمة المرور الحالية",
    newPassword: "كلمة المرور الجديدة",
    confirmNewPassword: "تأكيد كلمة المرور الجديدة",
    updatePassword: "تحديث كلمة المرور",
    profileCompletion: "استكمال الملف الشخصي",
    assign: "تعيين",
    completed: "مكتمل",
    missing: "غير مكتمل",
    arabic: "العربية",
    english: "الإنجليزية",
    french: "الفرنسية",

    signOut: "تسجيل الخروج",
    language: "اللغة",
    accountSettings: "إعدادات الحساب",
    myProfile: "ملفي الشخصي",
    student: "تلميذ",
    updatedPhoto: "!تم تغير الصورة",
    updatePhotoError: "حدث خطأ",

    requiredFirstname: "الإسم الأول مطلوب",
    requiredLastname: "اللقب مطلوب",
    requiredPhone: "رقم الهاتف مطلوب",
    invalidPhone: "يجب ان يتكون رقم الهاتف من 8 ارقام على الاقل",
    requiredAddress: "العنوان مطلوب",

    requiredCurrentPassword: "كلمة المرور الحالية مطلوبة",
    requiredNewPassword: "كلمة المرور الجديدة مطلوبة",
    requiredConfirmPassword: "تأكيد كلمة المرور مطلوب",
    passwordConfirmationCondition:
      "كلمة المرور يجب ان تحتوي على 4 أحرف أو اكثر",
    passwordNewCondition: "كلمة المرور يجب ان تحتوي على 4 أحرف أو اكثر",
    passwordMustMatch: "كلمة المرور يجب أن تطابق",
    passwordUpdated: "!تم تحديث كلمة المرور بنجاح",

    details: "التفاصيل",
    schoolarYear: "السنة الدراسية",
    uploadFiles: "رفع ملفات",
    selectSubmissionMethod: "إختر طريقة التسليم",
    writeAnswer: "كتابة إجابة",
    homeworkSubmitted: "تم تسليم واجبك بنجاح",
    answer: "إجابة",
    submit: "إجابة",
    trimester: "الثلاثية",
    allTrimesters: "جميع الثلاثيات",
    invalidSubmission: "حدث خطأ ما",
    invalidSubmissionText: "يجب ان تقوم بتسليم ملفات او ان تقوم بكتابة الإجابة",

    dashboard: "Générateur de mise",
    layoutBuilder: "En page",
    craft: "صناعة",
    pages: "الصفحات",
    profile: "ملف الشخصي",
    profileOverview: "Aperçu",
    projects: "المشاريع",
    campaigns: "Campagnes",
    documents: "الوثائق",
    connections: "Connexions",
    wizards: "Sorciers",
    horizontal: "افقي",
    vertical: "عمودي",
    account: "حساب",
    accountOverview: "Aperçu",
    authentication: "Authentification",
    basicFlow: "Flux de base",
    signIn: "تسجيل الدخول",
    signUp: "تسجيل حساب",
    passwordReset: "اعادة تعيين كلمة المرور",
    multiStepSignUp: "S'Inscrire Multi-Étapes",
    error404: "Erreur 404",
    error500: "Erreur 500",
    apps: "تطبيقات",
    chat: "دردشة",
    privateChat: "دردشة خاصة",
    groupChat: "دردشة جماعية",
    drawerChat: "Chat de tiroir",
    widgets: "Widgets",
    widgetsLists: "Listes",
    widgetsStatistics: "Statistiques",
    widgetsCharts: "Graphiques",
    widgetsMixed: "Mixte",
    widgetsTables: "Les tables",
    widgetsFeeds: "Flux",
    changelog: "Journal des modifications",
    docsAndComponents: "Documents & composants",
    megaMenu: "Méga Menu",
    exampleLink: "Exemple de lien",
    modals: "Modals",
    inviteFriends: "Inviter Des Amis",
    viewUsers: "Voir Les Utilisateurs",
    upgradePlan: "Plan De Mise À Niveau",
    shareAndEarn: "Partager Et Gagner",
    forms: "Formes",
    newTarget: "Nouvelle Cible",
    newCard: "Nouvelle Carte",
    newAddress: "Nouvelle Adresse",
    createAPIKey: "Créer Une Touche Api",
    twoFactorAuth: "Deux Facteurs D'Authentification",
    createApp: "Créer Une Application",
    createAccount: "Créer Un Compte",
    activity: "Activité",
    documentation: "Documentation",
    components: "Composants",
    customers: "Les Clients",
    gettingStarted: "Commencer",
    customersListing: "Liste Des Clients",
    customerDetails: "Détails Des Clients",
    calendarApp: "تقويم",
    subscriptions: "الاشتراكات",
    getStarted: "Commencer",
    subscriptionList: "Liste D'Abonnement",
    addSubscription: "Ajouter Un Abonnement",
    viewSubscription: "Voir L'Abonnement",
    builder: "الباني",
    home: "الرئيسية",
    todo: "الواجبات المنزلية",
    quiz: "تمرين سريع",
    submittedQuiz: "تم تقديم اختبارك بنجاح",
    quizSubmissionError: "يجب أن تقوم بإختيار اجابة على الاقل",
  },
};

const i18n = createI18n({
  legacy: false,
  locale: "fr",
  globalInjection: true,
  messages,
});

export default i18n;
