import { createApp } from "vue";
import App from "./App.vue";

/*
TIP: To get started with clean router change path to @/router/clean.ts.
 */
import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";
import i18n from "@/core/plugins/i18n";
import Socketio from "@/core/plugins/Socket.io";
//imports for app initialization
import ApiService from "@/core/services/ApiService";
import { initApexCharts } from "@/core/plugins/apexcharts";
import { initInlineSvg } from "@/core/plugins/inline-svg";
import { initVeeValidate } from "@/core/plugins/vee-validate";
import { initVueSelect } from "@/core/plugins/vue-select";

import "@/core/plugins/prismjs";
import "bootstrap";

const app = createApp(App);

app.use(store);
app.use(router);
app.use(ElementPlus);

app.use(Socketio, {
  connection: process.env.VUE_APP_SOCKET_URL,
  options: {
    transports: ["websocket"],
    path: process.env.VUE_APP_SOCKET_PATH,
    upgrade: false,
  },
});

ApiService.init(app);
initApexCharts(app);
initInlineSvg(app);
initVeeValidate();
initVueSelect(app);

app.use(i18n);

app.mount("#app");
