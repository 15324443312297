import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import router from "@/router";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface schoolarYear {
  _id: string;
  name?: string;
  start: Date;
  end: Date;
}

export interface User {
  lastLoginInfo: {
    date: Date;
  };
  gender: string;
  activities: Array<object>;
  archive: object;
  _id: string;
  firstName: string;
  lastName: string;
  BirthDate: Date;
  adress: string;
  phone: string;
  parent: string;
  status: string;
  createdAt: Date;
  updatedAt: Date;
  photo: string;
  classRoom: {
    emplois: {
      sem1: string;
      sem2: string;
      sem3: string;
    };
    status: string;
    level: number;
    teachers: Array<object>;
    _id: string;
    name: string;
    schoolarYear: string;
    inscription: number;
    monthly: number;
    createdAt: Date;
    updatedAt: Date;
  };
  schoolarYearsHistory: object;
  userName: string;
}

export interface UserAuthInfo {
  error: string;
  user: User;
  isAuthenticated: boolean;
}

export interface ServerConfig {
  base_url?: string;
  dbName: string;
  building: object;
}
@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {
  error = "";
  user = {} as User;
  isAuthenticated = !!JwtService.getToken();
  updatedProfileAlert = false;
  schoolarYears: object = {};
  activeSchoolarYear = "";
  buildingDB = "Root";
  serverConfig = {} as ServerConfig;
  trimester = 1 as number;
  /**
   * Get current building db
   * @returns String
   */
  get currentBuildingDB(): string {
    return this.buildingDB;
  }
  @Mutation
  [Mutations.SET_BUILDING_DB](db: string) {
    this.buildingDB = db;
  }

  /**
   * Get base_url
   * @returns ServerConfig
   */
  get serverConfigUrl(): ServerConfig {
    return this.serverConfig;
  }
  /**
   * Get trimester
   * @returns number
   */
  get currentTrimester(): number {
    return this.trimester;
  }
  /**
   * Get current user object
   * @returns User
   */
  get currentUser(): User {
    return this.user;
  }

  get isUpdatedProfileAlert(): boolean {
    return this.updatedProfileAlert;
  }

  /**
   * Get current user photo
   * @returns User
   */
  get userPhotoURL(): string {
    if (this.user.photo)
      return (
        this.serverConfigUrl.base_url + "/" + this.user.photo.replace("\\", "/")
      );
    return "media/avatars/blank.png";
  }

  /**
   * Verify user authentication
   * @returns boolean
   */
  get isUserAuthenticated(): boolean {
    return this.isAuthenticated;
  }

  /**
   * Get schoolar Years History
   * @returns object
   */
  get getSchoolarYears(): object {
    return this.schoolarYears;
  }

  /**
   * Get active schoolar Years
   * @returns string
   */
  get currentSchoolarYear(): string {
    return this.activeSchoolarYear;
  }

  /**
   * Get authentification errors
   * @returns array
   */
  get getError() {
    return this.error;
  }
  @Mutation
  [Mutations.SET_SERVER_CONFIG](serverConfig) {
    this.serverConfig = serverConfig;
  }
  @Mutation
  [Mutations.SET_TRIMESTER](trimester) {
    this.trimester = trimester;
  }
  @Mutation
  [Mutations.SET_ERROR](error) {
    this.error = error;
  }

  @Mutation
  [Mutations.SET_UPDATED_PROFILE_ALERT](value) {
    this.updatedProfileAlert = value;
  }

  @Mutation
  [Mutations.SET_SCHOOLER_YEARS](value) {
    this.schoolarYears = value;
  }

  @Mutation
  [Mutations.SET_ACTIVE_SCHOOLER_YEARS](value) {
    this.activeSchoolarYear = value;
    JwtService.saveSchoolarYear(value);
  }

  @Mutation
  [Mutations.SET_AUTH](user) {
    this.isAuthenticated = true;
    this.user = user.info;
    this.error = "";
    JwtService.saveToken(user.token);
    ApiService.setHeader();
  }

  @Mutation
  [Mutations.SET_USER](user) {
    this.user = user;
  }

  @Mutation
  [Mutations.SET_PHOTO](photo) {
    this.user.photo = photo;
  }

  @Mutation
  [Mutations.PURGE_AUTH]() {
    this.isAuthenticated = false;
    this.user = {} as User;
    this.error = "";
    JwtService.destroyToken();
    JwtService.destroySchoolarYear();
    JwtService.destroyDb();
    ApiService.clearHeader();
    router.push("/sign-in");
  }

  @Action
  [Actions.LOGIN](credentials) {
    /* JwtService.saveDb(credentials.schoolName);
    this.context.commit(Mutations.SET_BUILDING_DB, credentials.schoolName);
    ApiService.setHeaderBuilding(JwtService.getBuilding() as string);
    delete credentials.schoolName;*/
    return new Promise<void>((resolve, reject) => {
      return ApiService.post("lms/student/login", credentials)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_AUTH, data);

          this.context.dispatch(
            Actions.GET_SCHOOLAR_YEARS,
            this.currentUser.schoolarYearsHistory
          );
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.error.message);
          reject();
        });
    });
  }

  // login with parent token
  @Action
  [Actions.PARENT_LOGIN](credentials) {
    return new Promise<void>((resolve, reject) => {
      return ApiService.post(
        `lms/student/login_parent/${credentials.token}/${credentials.id}`,
        {}
      )
        .then(({ data }) => {
          this.context.commit(Mutations.SET_AUTH, data);

          this.context.dispatch(
            Actions.GET_SCHOOLAR_YEARS,
            this.currentUser.schoolarYearsHistory
          );
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.error.message);
          reject();
        });
    });
  }

  @Action
  [Actions.LOGOUT]() {
    this.context.commit(Mutations.PURGE_AUTH);
  }

  @Action
  [Actions.REGISTER](credentials) {
    return ApiService.post("register", credentials)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_AUTH, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.error.message);
      });
  }

  @Action
  [Actions.FORGOT_PASSWORD](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post("/lms/student/forgotpassword", payload)
        .then(() => {
          resolve();
        })
        .catch(() => {
          this.context.commit(Mutations.SET_ERROR, "error");
          reject();
        });
    });
  }

  @Action
  [Actions.VERIFY_AUTH]() {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      /* const building = JwtService.getBuilding() as string;
       ApiService.setHeaderBuilding(building);
       this.context.commit(Mutations.SET_BUILDING_DB, building);*/
      ApiService.get("lms/student/verify")
        .then(({ data }) => {
          this.context.commit(Mutations.SET_AUTH, data);
        })
        .catch(() => {
          this.context.commit(Mutations.PURGE_AUTH);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.SET_PHOTO](credentials) {
    if (JwtService.getToken()) {
      ApiService.patch(`lms/student/${this.user._id}/photo`, credentials)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_PHOTO, data.imageURL);
        })
        .catch(() => {
          this.context.commit(Mutations.SET_ERROR, "error");
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.UPDATE_USER](credentials) {
    if (JwtService.getToken()) {
      return new Promise<void>((resolve, reject) => {
        ApiService.patch(`lms/student/${this.user._id}/`, credentials)
          .then(() => {
            resolve();
          })
          .catch(() => {
            this.context.commit(Mutations.SET_ERROR, "Something Wrong");
            reject();
          });
      });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.UPDATE_PASSWORD](credentials) {
    if (JwtService.getToken()) {
      if (credentials["confirmpassword"]) delete credentials.confirmpassword;
      return ApiService.patch(
        `lms/student/${this.user._id}/password`,
        credentials
      )
        .then(() => {
          this.context.commit(Mutations.SET_ERROR, "");
        })
        .catch(() => {
          this.context.commit(Mutations.SET_ERROR, "Incorrect password");
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.GET_SCHOOLAR_YEARS](schoolarYearsHistory: string[]) {
    ApiService.post("schoolaryears/filter", {
      query: {},
      aggregation: [
        {
          $project: {
            _id: 1,
            start: 1,
            end: 1,
          },
        },
      ],
    }).then(async (res) => {
      const data: schoolarYear[] = [];

      for (const sc of res.data) {
        if (schoolarYearsHistory[sc._id])
          data.push({
            ...sc,
            name:
              new Date(sc["start"]).getFullYear() +
              "-" +
              new Date(sc["end"]).getFullYear(),
          });
      }
      this.context.commit(Mutations.SET_SCHOOLER_YEARS, data);
      this.context.commit(Mutations.SET_ACTIVE_SCHOOLER_YEARS, data[0]._id);
    });
  }
}
