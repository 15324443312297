import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/accueil",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/accueil",
        name: "accueil",
        component: () => import("@/views/accueil/index.vue"),
      },
      {
        path: "/notifications",
        name: "notifications",
        component: () => import("@/views/notifications/index.vue"),
      },
      {
        path: "/actualities",
        name: "actualities",
        component: () => import("@/views/actualities/index.vue"),
      },
      {
        path: "/profile",
        redirect: "/profile/overview",
        name: "profile",
        component: () => import("@/views/profile/Account.vue"),
        children: [
          {
            path: "overview",
            name: "profile-overview",
            component: () => import("@/views/profile/Overview.vue"),
          },
          {
            path: "settings",
            name: "profile-settings",
            component: () => import("@/views/profile/Settings.vue"),
          },
        ],
      },
      {
        path: "/subjects",
        name: "subjects",
        component: () => import("@/views/subjects/index.vue"),
      },
      {
        path: "/subject/:id",
        name: "subject",
        component: () => import("@/views/subjects/components/Subject.vue"),
      },
      {
        path: "/subject/:id/course/:cid",
        name: "course",
        component: () => import("@/views/subjects/components/Course.vue"),
      },
      {
        path: "/subject/:id/exercise/:hid",
        name: "exercise",
        component: () => import("@/views/subjects/components/Exercise.vue"),
      },
      {
        path: "/subject/:id/quiz/:hid",
        name: "quizDetails",
        component: () => import("@/views/quiz/Quiz.vue"),
      },
      {
        path: "/todo",
        name: "todo",
        component: () => import("@/views/todo/index.vue"),
      },
      {
        path: "/quiz",
        name: "quiz",
        component: () => import("@/views/quiz/index.vue"),
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/components/page-layouts/Auth.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () => import("@/views/authentication/basic-flow/SignIn.vue"),
      },
      {
        path: "/login/:token/:id",
        name: "login",
        component: () => import("@/views/authentication/basic-flow/login.vue"),
      },
      {
        path: "/sign-up",
        name: "sign-up",
        component: () => import("@/views/authentication/basic-flow/SignUp.vue"),
      },
      {
        path: "/password-forgot",
        name: "password-forgot",
        component: () =>
          import("@/views/authentication/basic-flow/PasswordForgot.vue"),
      },
      {
        path: "/password-reset/:token",
        name: "password-reset",
        component: () =>
          import("@/views/authentication/basic-flow/PasswordReset.vue"),
      },
    ],
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/authentication/Error404.vue"),
  },
  {
    path: "/500",
    name: "500",
    component: () => import("@/views/authentication/Error500.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to) => {
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  if (
    !["sign-in", "password-reset", "password-forgot", "login"].includes(
      String(to.name)
    )
  )
    store.dispatch(Actions.VERIFY_AUTH);

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;
